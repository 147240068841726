import React from "react";
import ScrollToTop from "react-scroll-to-top";

import ProfileImage from "../images/profile/ProfilePic.png";
import AboutPrestige40u40 from "../images/leveldesign/aboutme-40u40.png";
import AboutITFactor from "../images/leveldesign/aboutme-itfactor.png";
import AboutSGWIT100 from "../images/leveldesign/aboutme-sg100wit.png";
import AboutStarbreeze from "../images/leveldesign/aboutme-starbreeze.png";
import AboutLinkedinTopVoice from "../images/leveldesign/aboutme-topvoice.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import PDF from "../images/document/Rika-Lim-CV.pdf";

import "../styles/About.css";

export default function About() {
  return (
    <div className="About">
      <ScrollToTop smooth width="20" height="20" />
      <div className="hero-sub">
        <h1>About Me</h1>
      </div>

      <div className="container about-container rounded p-5 mb-5">
        <div className="row justify-content-center row-cols-1 row-cols-md-2 g-3">
          <div className="col pt-2 justify-content-left order-2 order-md-1">
            <div className="profile-name d-none d-md-block d-lg-block">
              Rika Lim{" "}
              <a
                className="icons_main_page"
                href="https://www.linkedin.com/in/jubbileus/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  classNameName="in-page-icon"
                />
              </a>
            </div>
            <div className="about-title">GAME DEVELOPER BASED IN EUROPE</div>
            <div className="about-paragraph">
              I have over a decade of experience creating immersive and engaging
              game worlds for popular franchises, such as PAYDAY and Assassin's
              Creed. I have led and mentored level design teams across multiple
              studios, working with other disciplines and stakeholders to
              deliver high-quality content and experiences.
            </div>

            <div className="about-paragraph">
              My achievements include leading the heist design direction and
              creation for PAYDAY 2&3, setting up the content roadmap and
              workflow for level designers, and reviewing the content and scope
              for heists. I was also a product owner and part of the world
              design team behind several regions in Assassin's Creed Valhalla,
              Odyssey and Origins; creating benchmark locations and features
              that improved the gameplay and story.
            </div>

            <div className="about-paragraph">
              My expertise is in level design, worldbuilding, team management
              and collaboration, content scope and risk assessment, and
              mentoring level designers on career development and work quality.
              I review portfolios, resumes, and projects for level designers
              regularly. I am always eager to learn new skills and technologies,
              like Unreal Engine 5, to improve my craft and adapt to the
              ever-changing industry.
            </div>

            <div className="about-paragraph">
              My CV is available for download{" "}
              <a
                href={PDF}
                target="_blank"
                rel="noreferrer"
                className="text-link"
              >
                HERE
              </a>{" "}
              .
            </div>
          </div>
          <div className="col order-1 order-md-2 justify-content-center">
            <div className="profile-name-sm d-sm-block d-md-none">Rika Lim</div>
            <img
              className="img-fluid rounded shadow profile-image"
              src={ProfileImage}
              alt="Rika Lim, 2024"
              width="400px"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="sub-heading">Highlights</div>
        <div className=" row row-cols-1 row-cols-md-3 g-4 text-center justify-content-center">
          <div className="col">
            <div className="card">
              <img
                src={AboutLinkedinTopVoice}
                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                alt="Rika Lim - Top Gaming Industry Voice on Linkedin"
              />
              <div className="card-body">
                <h5 className="card-title">Top Gaming Industry Voice</h5>
                <p className="card-text">Linkedin (2023)</p>
                <a
                  href="https://www.linkedin.com/posts/jubbileus_thank-you-very-much-everyone-didnt-realize-activity-7130092676254752770-GhLT?utm_source=share&utm_medium=member_desktop"
                  target="_blank"
                  rel="noreferrer"
                  className="navigate-btn btn btn-light"
                >
                  POST
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={AboutStarbreeze}
                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                alt="Starbreeze Employee Report 2021"
              />
              <div className="card-body">
                <h5 className="card-title">Employees Highlight</h5>
                <p className="card-text">Starbreeze Entertainment (2021)</p>
                <a
                  href="https://reports.starbreeze.com/en/2021/rika-lim/"
                  target="_blank"
                  rel="noreferrer"
                  className="navigate-btn btn btn-light"
                >
                  Article
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={AboutPrestige40u40}
                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                alt="Prestige Singapore 40 under 40 2021"
              />
              <div className="card-body">
                <h5 className="card-title">Prestige 40 under 40 (2021)</h5>
                <p className="card-text">Prestige Singapore</p>
                <a
                  href="https://www.prestigeonline.com/sg/prestige-40-under-40/rika-lim/"
                  target="_blank"
                  rel="noreferrer"
                  className="navigate-btn btn btn-light"
                >
                  Article
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={AboutITFactor}
                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                alt="Singapore Women in IT Factor"
              />
              <div className="card-body">
                <h5 className="card-title">SG Women with IT Factor</h5>
                <p className="card-text">The Straits Times</p>
                <a
                  href="https://www.straitstimes.com/tech/singapore-women-with-the-it-factor"
                  target="_blank"
                  rel="noreferrer"
                  className="navigate-btn btn btn-light"
                >
                  Article
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img
                src={AboutSGWIT100}
                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                alt="Singapore 100 Women in Tech 2020"
              />
              <div className="card-body">
                <h5 className="card-title">SG 100 Women in Tech 2020</h5>
                <p className="card-text">SGWIT, IMDA</p>
                <a
                  href="https://www.scs.org.sg/awards/sg100wit/profile?id=164"
                  target="_blank"
                  rel="noreferrer"
                  className="navigate-btn btn btn-light"
                >
                  Article
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
