import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";

import gameDevImage from "../images/videogames/homepage-payday3.png";
import levelWork from "../images/leveldesign/homepage-heart.png";
import miscWork from "../images/shecodes/homepage-misc.png";

import "../styles/Main.css";

export default function Main() {
  return (
    <div className="Main">
      <ScrollToTop smooth width="20" height="20" />
      <div className="hero-main">
        <h2>Senior Level Designer</h2>
        <h1>RIKA LIM</h1>
        <h3>
          As Level Designers, we aim to achieve a{" "}
          <strong>realization of gameplay and world with layouts</strong> that
          are engaging to navigate in. With interesting gameplay ingredients and
          objectives as compliment to the layout will also provide{" "}
          <strong>meaningful replayability & scalability</strong>. And most
          importantly, a good <strong>balance of logic and fun</strong> is
          important in creating the world and spaces.
        </h3>
        <a href="/#gettingstarted" className="button_main btn btn-light">
          GETTING STARTED
        </a>
      </div>

      <div className="container text-center" id="gettingstarted">
        <h3 className="home-content-heading">Portfolio</h3>
        <div className="row row-cols-1 row-cols-md-2 g-4 pt-5">
          <div className="col">
            <div className="card">
              <img
                src={gameDevImage}
                className="card-img-top img-fluid main-img-thumbnail mx-auto"
                alt="Payday 3"
              />
              <div className="card-body">
                <h5 className="card-title">Released Video Game Titles</h5>
                <p className="card-text">Credits in Video Games Development</p>
                <Link to="/videogames" className="navigate-btn btn btn-light">
                  Video Games
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              {" "}
              <img
                src={levelWork}
                className="card-img-top img-fluid main-img-thumbnail mx-auto"
                alt="Assassin's Creed Odyssey - Heart Island"
              />
              <div className="card-body">
                <h5 className="card-title">Game Development</h5>
                <p className="card-text">
                  Project Reels, Articles and Panel Discussion
                </p>
                <Link to="/leveldesign" className="navigate-btn btn btn-light">
                  Level Design
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 pt-5 justify-content-center">
          <div className="col">
            <div className="card">
              {" "}
              <img
                src={miscWork}
                className="card-img-top img-fluid main-img-thumbnail mx-auto"
                alt="SheCodes Workshops"
              />
              <div className="card-body">
                <h5 className="card-title">MISCELLANEOUS</h5>
                <p className="card-text">Personal Learning and Development</p>
                <Link to="/misc" className="navigate-btn btn btn-light">
                  Misc
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
